import { refreshAccesstoken } from "@/helpers"

// Handle Figma callback
const serviceCallback = async (endpoint: string) => {
  const response = await fetch(endpoint)
  const json = await response.json()
  // console.log(json)
  return json
}

// General fetch
const fetchData = async (endpoint: string) => {
  const validToken = await refreshAccesstoken()
  if (validToken) {
    const access_token = localStorage.getItem("access_token")
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(endpoint, { headers: headers })
    const json = await response.json()
    console.log(json)
    return json
  }
  return false
}

// Post data
const postData = async (endpoint: string, body: object) => {
  const validToken = await refreshAccesstoken()
  if (validToken) {
    const access_token = localStorage.getItem("access_token")
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(endpoint, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    })
      .then((resp) => {
        return resp
      })
      .then((resp) => {
        // console.log(resp)
        return resp
      })
      .catch((err) => {
        console.log(err)
        return err
      })
    const json = await response.json()
    return json
  }
}

// Post data
const putData = async (endpoint: string, body: object) => {
  const validToken = await refreshAccesstoken()
  if (validToken) {
    const access_token = localStorage.getItem("access_token")
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    }
    const response = await fetch(endpoint, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(body)
    })
      .then((resp) => {
        return resp
      })
      .then((resp) => {
        // console.log(resp)
        return resp
      })
      .catch((err) => {
        console.log(err)
        return err
      })
    const json = await response.json()
    return json
  }
}

// const get_data = await fetch("http://127.0.0.1:5000/api/v1/figma/get-team-id", {
//   method: "POST",

//   body: JSON.stringify(data.value)
// })
//   .then((resp) => {
//     if (!resp.ok) {
//       throw resp
//     }
//     return resp.json()
//   })
//   .then((resp_data) => {
//     data.value = { team_url: "" }
//     console.log(resp_data)
//   })
//   .catch((err) => {
//     err.json().then((errorMssg: object) => {
//       console.log(`Test: ${JSON.stringify(errorMssg.error)}`)
//     })
//   })

export { serviceCallback, fetchData, postData, putData }
