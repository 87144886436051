import { defineStore } from "pinia"

// interface userLayout {
//   id: number
//   email: string
//   handle: string
//   image_url: string
//   signed_up: string
//   figma_user_id: string
// }

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: {
        id: null,
        handle: null,
        image_url: null,
        signed_up: null,
        figma_user_id: null
      },
      has_asana: false,
      is_authenticated: false,
      teams: [],
      webhooks: [],
      asana_projects: null,
      asana_workspaces: null,
      total_tasks_created: 0,
      BASE_URL: process.env.VUE_APP_API_URL
    }
  },
  actions: {
    setUser() {
      const user = localStorage.getItem("user")
      if (user) {
        this.user = JSON.parse(user)
      }
    }
  }
})
