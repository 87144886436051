<template>
  <footer>
    <p>Created by <a href="#">@kevandrsn</a></p>
    <img
      width="64"
      height="64"
      alt="Memoji of Kevin Anderson"
      src="../../assets/img/kevin-anderson-memoji.png"
    />
  </footer>
</template>

<script setup lang="ts"></script>

<style scoped>
footer {
  background-color: #fafafa;
  min-height: 120px;
  /* height: 120px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  margin-top: auto;
}

footer p {
  color: #666;
  font-weight: 800;
}

footer p a {
  color: #333;
  text-decoration: none;
}

footer p a:hover {
  text-decoration: underline;
  color: #000;
}
</style>
