import { useUserStore } from "@/store"
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomeView from "../views/HomeView.vue"
import { logout } from "@/helpers"

// Get params from callback and pass it to the API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const callbackService = async (to: any) => {
  const service: string = to.name === "figma" ? "figma" : "asana"
  return {
    name: "home",
    params: {
      service: service,
      code: to.query.code,
      state: to.query.state
    }
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView
  },
  {
    path: "/figma/callback",
    name: "figma",
    component: HomeView,
    beforeEnter: [callbackService]
  },
  {
    path: "/asana/callback",
    name: "asana",
    component: HomeView,
    beforeEnter: [callbackService]
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
router.beforeEach((to) => {
  // const store = useUserStore()
  // const access_token = localStorage.getItem("access_token")
  // if (!access_token) {
  //   logout()
  // }
  // if(store.user)
  // console.log(to)
  // console.log(store.user.id)
})

export default router
