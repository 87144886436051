const isNotEmptyObject = (object: object) => {
  if (Object.keys(object).length != 0) {
    return true
  }
  return false
}

const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

const tokenExpired = (expirationTime: number) => {
  const now = Math.floor(Date.now() / 1000)
  console.log(`Exp: ${expirationTime - now}`)
  if (now < expirationTime) {
    return false
  }
  return true
}

const refreshAccesstoken = async () => {
  const access_token = localStorage.getItem("access_token")
  if (!access_token || access_token === "undefined") {
    console.log("Invalid token")
    return false
  }

  const parsedJwt = parseJwt(access_token)
  console.log(`Token expired ${tokenExpired(parsedJwt.exp)}`)
  if (tokenExpired(parsedJwt.exp)) {
    await fetch(`${process.env.VUE_APP_API_URL}/token/refresh`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
        "Content-Type": "application/json"
      },
      method: "post"
    }).then((resp) =>
      resp.json().then((resp) => {
        localStorage.setItem("access_token", resp.access_token)
      })
    )
  }
  return true
}

const logout = () => {
  localStorage.removeItem("access_token")
  localStorage.removeItem("refresh_token")
  localStorage.removeItem("asana_workspaces")
  localStorage.removeItem("user")
  localStorage.removeItem("has_asana")
  window.location.replace("https://retask.co")
}

export { isNotEmptyObject, parseJwt, tokenExpired, refreshAccesstoken, logout }
