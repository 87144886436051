const notify = (message: string, error: boolean) => {
  const notificationDiv = document.getElementById("notifications")
  const notificationTemplate = document.createElement("div")
  notificationTemplate.classList.add(
    "notification-wrapper",
    "show-notification"
  )
  notificationTemplate.innerHTML = `<div class="${
    error ? "error-memoji" : "success-memoji"
  }  "></div><div class="error-message"><h3>${
    error ? "Oops!" : "Success!"
  }</h3><p>${message}</p></div>`

  notificationDiv.appendChild(notificationTemplate)

  setTimeout(() => {
    notificationTemplate.remove()
  }, 5050)
}

export { notify }
